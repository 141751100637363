<template>
  <h4 class="content-title">标签设置</h4>
  <div class="content-subject">
    <p style="color: #b5bbc5;font-size:14px">在这里管理所有客户标签，通过标签可以方便客服了解客户。</p>
    <a-radio-group v-model:value="typeData" @change="typeChange">
      <a-radio v-for="item in typeList" :key="item.label" :value="item.value">{{item.label}}</a-radio>
    </a-radio-group>
    <div style="margin-top:15px">
      <a-button style="margin-bottom:15px" @click="visible = true">+创建新标签</a-button>
    </div>
    <a-table bordered :data-source="dataSource" :row-key="record => record.id" :columns="columns" :pagination="pagination" :loading="loading" @change="tableChange">
      <template #operation="{record}">
        <div>
          <a-popconfirm title="确认删除?" ok-text="确认" cancel-text="取消" @confirm="confirmDelete(record)">
            <a>删除</a>
          </a-popconfirm>
        </div>
      </template>
    </a-table>
    <a-modal v-model:visible="visible" title="创建新标签" @ok="addLabelConfirm">
      <div> 标签名称<span style="color:#f00">*</span></div>
      <a-input v-model:value="labelName" placeholder="请输入标签名称" />
      <span style="color:#0066cc">注：请确保新标签和已存在标签不重复</span>
    </a-modal>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'
import { getLabelType, getLabelPage, addLabel, deleteLabel } from '@/api/crmManagement/setRules'
import { message } from 'ant-design-vue'

export default {
  setup () {
    const state = reactive({
      labelName: '',
      typeData: 1,
      visible: false,
      loading: false,
      labelType: { value: 1, label: '' },
      typeList: [],
      dataSource: [],
      columns: [
        {
          title: '标签名称',
          dataIndex: 'name',
          width: '30%'
        },
        {
          title: '创建人',
          dataIndex: 'creator',
          width: '30%'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          width: '30%'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '10%',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      pagination: {
        current: 1,
        pageSize: 5,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['5', '10', '15'],
        showTotal: (total, range) => `共 ${total} 条`
      }
    })

    const loadData = () => {
      state.loading = true
      getLabelPage({
        current: state.pagination.current,
        labelType: state.labelType,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.dataSource = res.data.records
          state.pagination.total = res.data.total
          state.loading = false
        }
      }).catch(err => { console.log(err) })
    }
    onMounted(() => {
      getLabelType()
        .then(res => {
          if (res.code === 10000) {
            state.typeList = res.data
            state.labelType = state.typeList[0]
          }
        }).catch(err => { console.log(err) })
      if (state.labelType.value) loadData()
    })
    const addLabelConfirm = () => {
      addLabel({
        labelType: state.labelType,
        name: state.labelName
      }).then(res => {
        if (res.code === 10000) {
          message.success('添加标签' + res.msg)
          state.visible = false
          loadData()
        }
      }).catch(err => { console.log(err) })
    }
    const typeChange = (e, v) => {
      state.typeList.forEach(item => {
        if (state.typeData === item.value) state.labelType = item
      })
      loadData()
    }
    const tableChange = (e, v) => {
      state.pagination.current = e.current
      state.pagination.pageSize = e.pageSize
      loadData()
    }
    const confirmDelete = record => {
      deleteLabel({ labelId: record.id })
        .then(res => {
          if (res.code === 10000) {
            message.success('删除' + res.msg)
            loadData()
          }
        }).catch(err => { console.log(err) })
    }

    return {
      ...toRefs(state),
      typeChange,
      tableChange,
      confirmDelete,
      addLabelConfirm
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
