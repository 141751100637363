<template>
  <div class="describe">请认真配置下列规则，确保系统可以正常使用！</div>
  <div class="content-box">
    <a-menu v-model:openKeys="openKeys" v-model:selectedKeys="selectedKeys" style="width: 156px" mode="vertical">
      <a-menu-item v-for="item in menuList" :key="item.id" @click="menuClick(item.id)">
        <!-- <template #icon>
            <img class="menuIcon" src="@/assets/images/menuSign.png" />
          </template> -->
        {{item.label}}
      </a-menu-item>
    </a-menu>
    <div class="content">
      <SetPublic v-if="menuId === 1" />
      <SetLabel v-if="menuId === 2" />
      <ReturnRule v-if="menuId === 3" />
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import SetPublic from './setPublic.vue'
import SetLabel from './setLabel.vue'
import ReturnRule from './returnRule.vue'
export default {
  components: {
    SetPublic,
    SetLabel,
    ReturnRule
  },
  setup () {
    const state = reactive({
      openKeys: [],
      selectedKeys: [],
      menuId: null,
      menuList: [{
        id: 1,
        label: '领取规则'
      }, {
        id: 2,
        label: '标签设置'
      },{
        id:3,
        label:'退回机制设置'
      }]
    })
    const menuClick = id => {
      state.menuId = id
    }

    return {
      ...toRefs(state),
      menuClick
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
