<template>
  <h4 class="content-title">退回机制设置</h4>
  <div class="content">
    <div class="tips-content">
      <span style="color:#EC0C0C">温馨提示：</span>
      1、各部门根据自身需求制定私海退回机制，系统根据各部门设置的要求单独执行，不填写表示不参与，值范围请填写 1 到 360。
    </div>
    <div>
      <div>所属部门<span style="color:#f00">*</span></div>
      <a-select v-model:value="searchForm.orgId" style="width: 150px" placeholder="选择部门" @change="orgChange">
        <a-select-option v-for="item in $store.state.app.orgList" :key="item.id" :value="item.id">{{ item.name
          }}</a-select-option>
      </a-select>
    </div>
    <div style="margin:10px 0">
      <div><span style="color:#344563;font-weight: bold;">私海客户退回机制设置</span>（超时掉入临时客户）</div>
      <table border="1" style="width:100%">
        <thead>
          <tr>
            <th>分类</th>
            <th>规则</th>
            <th style="text-align: left;">说明</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>进入私海跟进</td>
            <td>超过 <a-input-number v-model:value="tableData.privateFirstFollowDay" :min="0" :max="360" /> 天无跟进</td>
            <td class="explain-context">从首次私海开始计算，超过多少天未跟进</td>
          </tr>
          <tr>
            <td>私海后续跟进</td>
            <td>超过 <a-input-number v-model:value="tableData.privateOtherFollowDay" :min="0" :max="360" /> 天无跟进</td>
            <td class="explain-context">从最后一次写跟进小计时间开始计算，连续多少天无跟进。</td>
          </tr>
          <tr>
            <td>超时未成交</td>
            <td>超过 <a-input-number v-model:value="tableData.privateToCoopDay" :min="0" :max="360" /> 天未成交</td>
            <td class="explain-context">第一次进入私海时间开始计算到规定时间未成交。</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <div><span style="color:#344563;font-weight: bold;">合作客户机制设置</span>（超时提醒）</div>
      <table border="1" style="width:100%">
        <thead>
          <tr>
            <th>分类</th>
            <th>规则</th>
            <th style="text-align: left;">说明</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>进入合作跟进</td>
            <td>超过 <a-input-number v-model:value="tableData.coopFollowDay" :min="0" :max="360" /> 天无跟进</td>
            <td class="explain-context">从转为合作客户开始计算。</td>
          </tr>
          <tr>
            <td>距上次未成交</td>
            <td>超过 <a-input-number v-model:value="tableData.coopTimeOutOrderDay" :min="0" :max="360" /> 天未成交</td>
            <td class="explain-context">从上一次成交开始计算，超过多少天未再次下单给出提醒。</td>
          </tr>
        </tbody>
      </table>
      <div style="margin-top:10px">
        <a-popconfirm title="确认修改吗?" ok-text="确认" cancel-text="取消" @confirm="confirmEdit">
          <a-button type="primary">保存设置</a-button>
        </a-popconfirm>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'
import { returnLook, confirmReturnEdit } from '@/api/crmManagement/setRules'
import { message } from 'ant-design-vue'

export default {
  setup () {
    const state = reactive({
      value: null,
      tableData: {},
      searchForm: {
        orgId: null
      },
    })
    onMounted(() => {

    })
    const loadData = () => {
      state.tableData = {}
      state.tableData.orgId = state.searchForm.orgId
      returnLook(state.searchForm.orgId).then(res => {
        if (res.code === 10000 && res.data) {
          state.tableData = res.data
        }
      })
    }
    const confirmEdit = () => {
      if (state.searchForm.orgId !== null) {
        confirmReturnEdit({
          ...state.tableData
        }).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
          }
        })
      } else message.error('请选择部门')
    }
    const orgChange = () => {
      state.tableData.orgId = state.searchForm.orgId
      loadData()
    }
    return {
      ...toRefs(state),
      confirmEdit,
      loadData,
      orgChange
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';

.content {
  padding: 10px;
  background-color: #fff;
}

.tips-content {
  padding: 10px 15px;
  color: #6B778C;
  background-color: #F7F8FA;
}

.explain-context {
  color: #999;
}

table,
th,
td {
  border: 1px solid rgba(230, 230, 230, 1);
}

td,
th {
  padding: 8px;
}

th {
  text-align: center;
}
</style>
